import { TokenResponse } from '../types/koala';

export const getToken = (): string | null => localStorage.getItem('token');

export const hasToken = (): boolean => {
  const token = getToken();
  return token === undefined || token === null;
};

export const saveToken = (response: TokenResponse): void => {
  localStorage.setItem('token', response.access_token);
  localStorage.setItem('tokenType', response.token_type);
  localStorage.setItem('refreshToken', response.refresh_token);
};

export const removeToken = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenType');
  localStorage.removeItem('refreshToken');
};

export const getType = (): string | null => localStorage.getItem('tokenType');

export const getRefreshToken = (): string | null => localStorage.getItem('refreshToken');
