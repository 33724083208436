import React, { Component } from 'react';
import styled from 'styled-components';
import * as Token from '../services/token';
import * as Koala from '../services/koala';
import Auth from './Auth';
import DiscountForm from './DiscountForm';
import Button from '../components/button';
import { ENV } from '../constants/env';

// eslint-disable-next-line
interface Props {}

interface State {
  isLoading: boolean;
  isLoggedIn: boolean;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (Token.hasToken()) {
      this.state = { isLoading: false, isLoggedIn: false };
    } else {
      this.state = { isLoading: true, isLoggedIn: false };
      this.verifyLogin();
    }
    this.callback = this.callback.bind(this);
    this.signout = this.signout.bind(this);
  }

  async verifyLogin(): Promise<void> {
    try {
      await this.orgCheck();
    } catch (e) {
      try {
        const result = await Koala.refreshToken();
        if (!result.scopes.includes('group:organization_admin')) {
          throw new Error('Not A CMS User');
        }
        Token.saveToken(result);
        await this.orgCheck();
      } catch (e2) {
        Token.removeToken();
        this.setState({ isLoading: false, isLoggedIn: false });
      }
    }
  }

  async orgCheck(): Promise<void> {
    const org = await Koala.getOrganization();
    if (org.id !== ENV.REACT_APP_KOALA_ORGANIZATION_ID) {
      throw new Error('Not valid for this org');
    }
    this.setState({ isLoading: false, isLoggedIn: true });
  }

  callback(isLoggedIn: boolean): void {
    if (!isLoggedIn) {
      Token.removeToken();
    }
    this.setState({ isLoggedIn });
  }

  signout(): void {
    Token.removeToken();
    this.setState({ isLoading: false, isLoggedIn: false });
  }

  render(): React.ReactNode {
    const { isLoggedIn, isLoading } = this.state;
    return (
      <Wrapper>
        {!isLoggedIn && !isLoading && (
          <Auth doLogin={this.callback} />
        )}
        {isLoggedIn && !isLoading && (
          <DiscountForm />
        )}
        {isLoggedIn && !isLoading && (
          <Button htmlType="submit" type="primary" onClick={this.signout}>
            Logout
          </Button>
        )}
      </Wrapper>
    );
  }
}

export default App;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
