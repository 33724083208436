import axios from 'axios';
import { ENV } from '../constants/env';
import { TokenResponse, ConfigWrapper, Organization } from '../types/koala';
import * as Token from './token';

const axiosClient = axios.create({
  baseURL: ENV.REACT_API_URL,
});

export const getToken = async (username: string, password: string): Promise<TokenResponse> => {
  const requestURL = 'oauth/access_token';
  const clientTokenPayload = {
    grant_type: 'password',
    client_id: ENV.REACT_APP_CLIENT_ID,
    client_secret: ENV.REACT_APP_CLIENT_SECRET,
    scope: 'group:organization_admin',
    password,
    username,
  };
  const response = await axiosClient.post<TokenResponse>(requestURL, clientTokenPayload);
  return response.data;
};

export const refreshToken = async (): Promise<TokenResponse> => {
  const requestURL = 'oauth/access_token';
  // eslint-disable-next-line
  const refresh_token = Token.getRefreshToken();
  const clientTokenPayload = {
    grant_type: 'refresh_token',
    client_id: ENV.REACT_APP_CLIENT_ID,
    client_secret: ENV.REACT_APP_CLIENT_SECRET,
    scope: 'group:organization_admin',
    refresh_token,
  };
  const response = await axiosClient.post<TokenResponse>(requestURL, clientTokenPayload);
  return response.data;
};

export const getOrganization = async (): Promise<Organization> => {
  const requestURL = 'v1/config';
  const tokenType = Token.getType();
  const token = Token.getToken();
  const response = await axiosClient.get<ConfigWrapper>(requestURL, {
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.data.data.organization;
};
