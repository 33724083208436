import React from 'react';

interface Props {
  className: string,
}

/* eslint-disable */
const Copyright = (props: Props) => (
  <p className={props.className}>
    &copy; {new Date().getFullYear()} Koala Labs
    <span style={{ display: 'block', fontSize: 10, color: '#999' }}>
      {process.env.REACT_APP_VERSION}
      {process.env.REACT_APP_ENV !== 'PRODUCTION' && ` - ${process.env.REACT_APP_ENV}`}
    </span>
  </p>
);

export default Copyright;
