import BRAND_ENV from './json/env.json';

interface IEnv {
  REACT_APP_ENV: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_CLIENT_SECRET: string;
  REACT_APP_KOALA_ORGANIZATION_ID: number;
  REACT_APP_SENTRY: boolean;
  REACT_API_URL: string;
  REACT_REFUND_API_URL: string;
}

interface Configs {
  [key: string]: IEnv;
}

const currentEnv = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'DEV');

const configs: Configs = BRAND_ENV;

export const ENV: IEnv = configs[currentEnv];
