// Global styles
const RED = '#AD3E33';
const LIGHTBLUE = 'rgb(241, 243, 246)';
const LIGHTGRAY = '#D8D8D8';
const LIGHTERGRAY = 'rgba(239, 239, 239, 0.5)';
const ALERT = '#F44336';
const OK = '#B4F7A5';
const WARNING = '#FFC107';
const WHITE = '#FFFFFF';

export const COLORS = {
  PRIMARY: RED,
  RED,
  OK,
  ALERT,
  WARNING,
  LIGHTBLUE,
  LIGHTGRAY,
  LIGHTERGRAY,
  WHITE,
};
