import React, { Component } from 'react';
import styled from 'styled-components';
import Copyright from '../components/copyright';
import Button from '../components/button';
import Input from '../components/input';
import * as Koala from '../services/koala';
import * as Token from '../services/token';
import { COLORS } from '../constants/colors';
import { ENV } from '../constants/env';

const LOGO = 'https://koala.io/img/koala-logo@3x.png';

interface Props {
  doLogin: (isLoggedIn: boolean) => void;
}

interface State {
  username: string;
  password: string;
  loading: boolean;
  error: string | null;
}

class Auth extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
  }

  handleUsername(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ username: event.target.value });
  }

  handlePassword(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ password: event.target.value });
  }

  async handleSubmit(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    this.setState({ loading: true });
    try {
      const { username, password } = this.state;
      const result = await Koala.getToken(username, password);
      if (!result.scopes.includes('group:organization_admin')) {
        throw new Error('Not A CMS User');
      }
      Token.saveToken(result);
      const org = await Koala.getOrganization();
      if (org.id !== ENV.REACT_APP_KOALA_ORGANIZATION_ID) {
        throw new Error('Not valid for this org');
      }
      this.setState({ loading: false });
      const { doLogin } = this.props;
      doLogin(true);
    } catch (e) {
      Token.removeToken();
      this.setState({ loading: false, error: e.message });
    }
  }

  render(): React.ReactNode {
    const {
      username,
      password,
      error,
      loading,
    } = this.state;
    return (
      <AuthPage>
        <div className="auth-content">
          <Logo src={LOGO} />
          <div>
            <form onSubmit={this.handleSubmit}>
              <Input
                size="large"
                placeholder="Username"
                name="username"
                value={username}
                onChange={this.handleUsername}
              />
              <Input
                size="large"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={this.handlePassword}
                error={error}
              />
              <div style={{ textAlign: 'right' }}>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Sign In
                </Button>
              </div>
            </form>
          </div>
          <Copyright className="copyright" />
        </div>
      </AuthPage>
    );
  }
}

export default Auth;

export const AuthPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-size: cover;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .auth-content {
    width: 500px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 70px 50px;
    position: relative;
    z-index: 10;
    background-color: #ffffff;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .auth-bottom {
    border-top: 1px dashed ${COLORS.LIGHTGRAY};
    margin-top: 20px;
    padding-top: 20px;
    text-align: center;
  }

  .copyright {
    bottom: 40px;
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
  }
`;

export const Logo = styled.img`
  max-width: 300px;
  margin: 0 auto 50px;
  max-width: 100%;
  max-height: 250px;
`;

export const ResetButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  &:hover {
    text-decoration: underline;
  }
`;
