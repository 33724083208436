import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import axios from 'axios';
import { RadioChangeEvent } from 'antd/lib/radio';
import Button from '../components/button';
import Input from '../components/input';
import * as Refund from '../services/refund';

/* eslint-disable */
function DiscountForm() { 
    const [discountValue, setDiscountValue] = useState<string>("0");
    const [discountMinimum, setDiscountMinimum] = useState<string>("0");
    const [discountType, setDiscountType] = useState<string>("percentage");
    const [splashImage, setSplashImage] = useState<string>("backgroundImage");
    const [splashFile, setSplashFile] = useState<File | null>(null);

    const loadConfig = async() => {
        const config = await Refund.getConfig();
        setDiscountType(config.type);
        setDiscountValue(config.value.toString());
        setDiscountMinimum(config.minimum ? config.minimum.toString() : "0");
    }

    useEffect(() => {
        loadConfig();
    }, []);

    const save = async() => {
        console.log(splashFile?.type);
        const newConfig = await Refund.saveConfig({
            type: discountType,
            value: parseInt(discountValue),
            minimum: parseInt(discountMinimum),
            fileMime: splashFile?.type,
        });
        const response = await axios.put(newConfig.uploadURL, splashFile, {
            headers: {
                'Content-Type': splashFile?.type,
                'x-amz-acl': 'public-read',
            },
        });
        if (response.status >= 200 && response.status < 300) {
            setSplashImage("backgroundImage?" + Date.now());
        }
    }

    const handleDiscountTypeChange = (event: RadioChangeEvent) => {
        setDiscountType(event.target.value);
    }

    const handleDiscountValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountValue(event.target.value);
    }

    const handleDiscountMinimumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountMinimum(event.target.value);
    }
    
    const handleSplashImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files) {
            setSplashImage(URL.createObjectURL(event.target.files[0]));
            setSplashFile(event.target.files[0]);
        }        
    }

    return (    
        <Wrapper>
            <Ant.Form layout="vertical">
                <Ant.Form.Item label="Select a discount type">
                    <Ant.Radio.Group onChange={handleDiscountTypeChange} defaultValue={discountType} value={discountType}>
                        <Ant.Radio.Button value="flat">Flat</Ant.Radio.Button>
                        <Ant.Radio.Button value="percentage">Percentage</Ant.Radio.Button>
                    </Ant.Radio.Group>
                    {discountType === 'flat' &&
                        <div>
                            A flat refund will only be issued if the payment value is greater than the discount.
                        </div>
                    }
                    {discountType === 'percentage' &&
                        <div>A percentage refund is applied to the payment value before tax and other fees.</div>
                    }

                </Ant.Form.Item>
                <Ant.Form.Item label="Setup the discount amount">
                    <Input type="number"
                        placeholder="5"
                        name="discount_value"
                        value={discountValue}
                        onChange={handleDiscountValueChange}
                        min={0}
                        precision={0}
                        max={discountType === 'percentage' && 100}
                        prefix={discountType === 'flat' && "¢"}
                        suffix={discountType === 'percentage' && "%"}/>

                </Ant.Form.Item>

                <Ant.Form.Item label="Set a minimum amount before the discount will apply">
                    <Input type="number"
                        placeholder="0"
                        name="minimum"
                        value={discountMinimum}
                        onChange={handleDiscountMinimumChange}
                        min={0}
                        precision={0}
                        prefix="¢"
                    />
                </Ant.Form.Item>

                <Ant.Form.Item label="Choose the splash image">
                    <ImageWrapper>
                        <Preview src={splashImage} />
                        <Ant.Input type="file" name="splash-image" onChange={handleSplashImageSelection} />
                    </ImageWrapper>
                </Ant.Form.Item>
                <Ant.Form.Item>
                    <Button type="primary" onClick={save}>Submit</Button>
                </Ant.Form.Item>
            </Ant.Form>
        </Wrapper>      
    );
}

export default DiscountForm;

export const Wrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin: auto;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const Preview = styled.img`
  max-width: 200px;
  max-height: 200px;
`;