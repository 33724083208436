import * as React from 'react';
import * as Ant from 'antd';
import styled from 'styled-components';
import { Error } from './styles';

/* eslint-disable */
interface Props {
  disabled?: boolean,
  type?: string,
  children?: any,
  addonAfter?: any,
  addonBefore?: any,
  placeholder?: string,
  size?: any,
  touched?: any,
  error?: any,
  min?: any,
  max?: any,
  precision?: any,
  prefix?: any,
  suffix?: any,
  onChange?: any,
  value?: any,
  name?: string,
  className?: string,
}

const Input = (props: Props) => (
  <div style={{ position: 'relative', paddingBottom: 25 }}>
    <StyledInput
      {...props}
    >
      {props.children}
    </StyledInput>
    <Error>{props.error}</Error>
  </div>
);

export default Input;

export const StyledInput = styled(Ant.Input)`
  &.ant-input-lg {
    // Ant specific overrides
    font-size: 13px;
    height: 42px;
    padding: 6px 10px;
  }
`;
