import * as React from 'react';
import * as Ant from 'antd';
import styled from 'styled-components';

/* eslint-disable */
interface Props {
  /** The actual content of the button. Passed between the Button tags, NOT as an attribute. */
  children: any,
  className?: string,
  onClick?: any,
  type?: 'link' | 'text' | 'default' | 'ghost' | 'primary' | 'dashed',
  style?: object,
  htmlType?: 'button' | 'submit' | 'reset',
  loading?: boolean,
  disabled?: boolean,
  size?: any,
}
/* eslint-enable */

// eslint-disable-next-line
const Button = (props: Props) => (
  // eslint-disable-next-line
  <StyledButton {...props}>{props.children}</StyledButton>
);

export default Button;

export const StyledButton = styled(Ant.Button)`
  // Ant specific overrides
  &.ant-btn {
    font-size: 14px;
    height: 32px;
  }
  &.ant-btn-sm {
    font-size: 14px;
    height: 24px;
  }
  &.ant-btn-lg {
    height: 42px;
  }
`;
