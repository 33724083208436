import axios from 'axios';
import { ENV } from '../constants/env';
import DiscountConfigSpec, { JSONSpec } from '../model/DiscountConfig';
import * as Token from './token';

const axiosClient = axios.create({
  baseURL: ENV.REACT_REFUND_API_URL,
});

export const getConfig = async (): Promise<DiscountConfigSpec> => {
  const requestURL = '/config';
  const tokenType = Token.getType();
  const token = Token.getToken();
  const response = await axiosClient.get<DiscountConfigSpec>(requestURL, {
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.data as DiscountConfigSpec;
};

export const saveConfig = async (discount: JSONSpec): Promise<DiscountConfigSpec> => {
  const requestURL = '/config';
  const tokenType = Token.getType();
  const token = Token.getToken();
  const response = await axiosClient.post<DiscountConfigSpec>(requestURL, discount, {
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.data as DiscountConfigSpec;
};
